<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 415px">
        <field name="imagen" widget="image" style="width: 54px; height: 54px; top: 4px; left: 6px" />
        <field name="codigo" widget="input" searchable label="Código" placeholder="Cód." help="Cód. del Representante"
          width="50px" style="top: 9px; left: 68px" :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;" />
        <field name="nombre" widget="input" label="Nombre" help="Nombre del Representante" required
          placeholder="Nombre del Representante" searchable width="240px" style="top: 9px; left: 122px"
          inputStyle="font-weight:bold;" @input="onInputNombreComercial" @focus="onFocusNombreComercial" />
        <field name="activo" widget="toggle" ontext="Activo" offtext="Inactivo"
          :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="75" inline-label
          style="top: 19px; left: 368px" />
        <field name="usuario_id" widget="m2o" dbAdapter="usuario" placeholder="Usuario" help="Usuario" label="Usuario"
          labelProp="nombre" primary="id" searchable width="170px" style="top: 9px; left: 475px" />
        <!--<field name="write_date" widget="int" inline-label placeholder="F.U.M." readonly="true" width="120px" style="top:24px; left:528px;"/>-->
        <r-tabs ref="main-tabs" style="top: 55px; left: 0px; height: 350px" :buttons="[
          'Fiscal',
          'Personal',
          'Comisión',
          'Tipos Comisión',
          'Banco',
          'APP',
          'Saldos',
        ]">
          <div class="tab">
            <field name="nombre_fiscal" widget="input" label="Nombre" help="Razon Social" inline-label
              placeholder="Nombre Fiscal" searchable width="260px" style="top: 10px; right: 285px"
              @blur="onBlurNombreFiscal" />
            <field name="nif_cif" widget="input" label="NIF/CIF" help="NIF/CIF del Representante" inline-label required
              placeholder="NIF/CIF" searchable width="100px" style="top: 40px; right: 445px" @change="onChangeNifCif"
              :class="{
                wrong:
                  nif_cif_duplicado ||
                  (formData.nif_cif &&
                    !$utils.validation.checkVATNumber(formData.nif_cif)),
              }" />
            <field name="direccion_fiscal" widget="input" label="Direccion" help="Direccion Fiscal del Representante"
              inline-label placeholder="Direccion fiscal" searchable width="300px" style="top: 70px; right: 245px"
              @change="onChangeCopy('direccion_fiscal', 'direccion_comercial')" />
            <field name="direccion_fiscal1" widget="input" label help="Direccion Fiscal" inline-label
              placeholder="Direccion fiscal" searchable width="300px" style="top: 100px; right: 245px" @change="
                onChangeCopy('direccion_fiscal1', 'direccion_comercial1')
                " />
            <field name="cp_fiscal" inputalign="center" max="8" help="C.P. del Representante" widget="input"
              label="C.P." inline-label placeholder="CP" searchable width="64px" style="top: 130px; right: 485px"
              @change="onChangeCopy('cp_fiscal', 'cp_comercial')" />
            <field name="poblacion_fiscal" widget="input" inline-label placeholder="Poblacion fiscal" searchable
              width="241px" style="top: 130px; right: 244px"
              @change="onChangeCopy('poblacion_fiscal', 'poblacion_comercial')" />
            <field name="provincia_fiscal_id" dbAdapter="provincia" help="Provincia del Representante" widget="m2o"
              label="Provincia" inline-label placeholder="Provincia fiscal" searchable width="260px"
              style="top: 160px; right: 285px" :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]" @select="
                onChangeCopy('provincia_fiscal_id', 'provincia_comercial_id')
                " />
            <field name="pais_fiscal_id" dbAdapter="pais" widget="m2o" help="País del Representante"
              placeholder="País del Representante " label="País" inline-label searchable width="260px"
              style="top: 190px; right: 285px" @select="onChangeCopy('pais_fiscal_id', 'pais_comercial_id')" :fields="[
                { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                'nacionalidad',
                { name: 'moneda_id', fields: ['nombre'] },
              ]" />

            <field name="delegacion_id" widget="m2o" dbAdapter="delegacion" label="Delegación"
              help="Delegación a la que pertenece el Representante" searchable inline-label width="170px"
              style="top: 10px; right: 17px" />
            <field name="telefono_fiscal" widget="input" label="Teléfono" help="Teléfono del Representante" inline-label
              placeholder="Teléfono" searchable width="120px" style="top: 40px; right: 67px"
              @change="onChangeCopy('telefono_fiscal', 'telefono_comercial')" />
            <field name="movil_fiscal" widget="input" label="Móvil" help="Teléfono Móvil del Representante" inline-label
              placeholder="Teléfono Móvil" searchable width="120px" style="top: 70px; right: 70px"
              @change="onChangeCopy('movil_fiscal', 'movil_comercial')" />
            <field name="email_fiscal" widget="input" label="E-Mail" help="E-Mail del Representante" inline-label
              placeholder="e-mail" searchable width="180px" :class="{
                wrong:
                  formData.email_fiscal &&
                  !$utils.validation.checkEmail(formData.email_fiscal),
              }" style="top: 100px; right: 10px" @change="onChangeCopy('email_fiscal', 'email_comercial')" />
            <field name="web_fiscal" widget="input" help="Web del Representante" label="Web" inline-label
              placeholder="Web" searchable width="180px" style="top: 130px; right: 10px" />

            <field name="almacen" widget="m2o" dbAdapter="almacen" label="Almacén" searchable inline-label width="180px"
              style="top: 176px; right: 10px" :readonly="true" />
          </div>
          <div class="tab">
            <field name="nombre" widget="input" label="Nombre" inline-label placeholder="Nombre del representante"
              help="Nombre del representante" searchable width="260px" style="top: 20px; right: 285px" />
            <field name="direccion_comercial" max="30" widget="input" label="Dirección"
              help="Dirección del Representante" inline-label placeholder="Direccion Comercial" searchable width="260px"
              style="top: 53px; right: 285px" />
            <field name="direccion_comercial1" max="30" widget="input" label inline-label searchable width="260px"
              style="top: 86px; right: 285px" />
            <field name="cp_comercial" help="C.P. del Representante" max="8" inputalign="center" widget="input"
              label="C.P." inline-label placeholder="CP" searchable width="64px" style="top: 119px; right: 481px" />
            <field name="poblacion_comercial" max="25" widget="input" label inline-label placeholder="Población"
              searchable width="196px" style="top: 119px; right: 285px" />
            <field name="provincia_comercial_id" widget="m2o" dbAdapter="provincia" help="Provincia del Representante"
              label="Provincia" inline-label placeholder="Provincia" searchable width="260px" :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]" style="top: 152px; right: 285px" />
            <field name="pais_comercial_id" widget="m2o" dbAdapter="pais" help="País del Representante" label="País"
              inline-label searchable width="260px" style="top: 185px; right: 285px" />

            <field name="telefono_comercial" max="19" widget="input" label="Teléfono" help="Teléfono del Representante"
              inline-label placeholder="Teléfono" searchable width="120px" style="top: 20px; right: 69px" />
            <field name="telefono_comercial1" help="Teléfono del Representante" max="19" widget="input"
              label="Teléfono 1" inline-label placeholder="Teléfono 1" searchable width="120px"
              style="top: 53px; right: 69px" />
            <field name="movil_comercial" max="19" widget="input" label="Móvil" help="Teléfono Móvil del Representante"
              inline-label placeholder="Teléfono Móvil" searchable width="120px" style="top: 86px; right: 69px" />
            <field name="fax_comercial" max="19" widget="input" label="Fax" inline-label placeholder="Fax"
              help="Fax del Representante" searchable width="120px" style="top: 119px; right: 69px" />
            <field name="email_comercial" max="40" widget="input" label="E-Mail" help="E-Mail del Representante"
              inline-label placeholder="e-mail" searchable width="180px" style="top: 152px; right: 10px" :class="{
                wrong:
                  formData.email_comercial &&
                  !$utils.validation.checkEmail(formData.email_comercial),
              }" />
          </div>
          <div class="tab">
            <field name="comision_fija_liquidacion" widget="float" type="number" dec="2"
              label="Comision Fija por Liquidacion" placeholder="0.00" help="Comision Fija por Liquidacion" inline-label
              inputalign="center" width="40px" style="top: 10px; right: 458px" />
            <field name="impuesto_id" widget="m2o" label="Impuesto" inline-label searchable width="150px"
              style="top: 40px; left: 150px" :fields="['nombre', 'valor']" :template-result="(item) =>
                item.nombre &&
                item.nombre +
                ' (' +
                $utils.misc.formatNumber(item.valor) +
                '%)'
                " :template-selection="(item) =>
                  item.nombre &&
                  item.nombre +
                  ' (' +
                  $utils.misc.formatNumber(item.valor) +
                  '%)'
                  " />
            <field name="irpf" widget="float" type="number" dec="2" label="% I.R.P.F." placeholder="0.00 %"
              help="Retencion % I.R.P.F. a aplicar" inline-label inputalign="center" width="50px"
              style="top: 64px; right: 300px" />

            <fieldset style="
                position: absolute;
                left: 10px;
                top: 70px;
                width: 230px;
                height: 59px;
              ">
              <legend>Nacionalidad:</legend>
              <div style="position: relative">
                <field name="nacionalidad" widget="radio" default="nacional" :options="{
                  Nacional: 'nacional',
                  'Extracom.': 'extracomunitario',
                  UE: 'union_europea',
                }" inline-label style="left: 8px" />
              </div>
            </fieldset>
            <field v-if="formData.nacionalidad == 'union_europea'" name="intrastat" value="false" widget="toggle"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" label="Intrastat" inline-label
              style="top: 80px; left: 250px" />
            <field v-if="formData.nacionalidad == 'union_europea' && formData.intrastat
            " name="numero_intrastat" widget="input" label="Nº" style="top: 108px; left: 245px" width="90px"
              inline-label />

            <field name="penalizacion_forma_pago" widget="toggle" label="Penalizacion en funcion forma de pago"
              help="Tiene penalizacion en funcion de la forma de pago" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top: 140px; right: 300px" />
            <field name="comision_nomina" widget="toggle" label="Cobra las comisiones por nomina"
              help="Cobra comision por nomina" :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label
              style="top: 170px; right: 300px" />
            <!--<field
              name="tarifa"
              label="Tarifa"
              inline-label
              width="65px"
              widget="select"
              :options="{'1':'1','2':'2','3':'3','4':'4','5':'5','6':'6','7':'7','8':'8','9':'9','10':'Web'}"
              style="top:200px; right:300px;"
            />-->
            <field name="forma_pago_id" dbAdapter="forma_pago" widget="m2o" label="Forma pago" inline-label
              width="212px" style="top: 200px; right: 10px" label-prop="descripcion" />
            <fieldset style="
                position: absolute;
                right: 10px;
                top: 5px;
                width: 275px;
                height: 104px;
              ">
              <legend>Cobro de Comisiones:</legend>
              <div style="position: relative">
                <field name="calculo_comisones" widget="radio" default="porcentaje_pagado" :options="{
                  '% de la factura pagado por el cliente':
                    'porcentaje_pagado',
                  'Factura pagada por el cliente': 'pagado_completo',
                  'Sin restricciones': 'sin_restricciones',
                }" :inline-options="false" style="left:7px:" :width="254" />
              </div>
              <!--<field name="en_funcion_pagado_cliente" widget="rcswitcher" ontext="Si" offtext="No" label="% de la factura pagado por el cliente" help="En funcion del % de la factura pagado por el cliente" width="
			  " inline-label style="top:21px; left:10px;"/>
                        <field name="factura_pagada_cliente" widget="rcswitcher" ontext="Si" offtext="No" label="Factura pagada por el cliente" help="Solo cuando la factura esta pagada por el cliente" width="70" inline-label style="top:54px; left:57px;"/>
              <field name="sin_restricciones" widget="rcswitcher" ontext="Si" offtext="No" label="Sin restricciones" help="Sin restricciones" width="70" inline-label style="top:87px; left:129px;"/>-->
            </fieldset>
            <fieldset style="
                position: absolute;
                right: 10px;
                top: 110px;
                width: 275px;
                height: 85px;
              ">
              <legend>Comision en funcion Precio Venta</legend>
              <div style="position: relative">
                <field name="descuento_linea" widget="toggle" label="Incluir el Descuento de Linea"
                  :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="left: 10px" />
                <field name="descuento_especial" widget="toggle" label="Incluir el Descuento Especial"
                  :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 30px; left: 10px" />
              </div>
            </fieldset>
          </div>
          <div class="tab">
            <field name="tipos_comision" widget="handsontable" style="top: 5px; left: 5px" :readonly="true" :columns="[
              {
                name: 'comision_id',
                header: 'Comisión',
                type: 'm2o',
                primary: 'codigo',
                label: 'descripcion',
                showCode: true
              },
              {
                name: 't',
                header: 'T',
                type: 'select',
                options: ['V', 'P', 'U', 'B'],
              },
              {
                name: 'divisa_id',
                header: ' ',
                type: 'm2o',
                primary: 'codigo',
                label: 'simbolo'
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
              },
              {
                name: 'peso_incmerma',
                header: 'Inc.Merma',
                type: 'checkbox',
              },
              {
                name: 'peso_directas',
                header: 'Peso Directas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'peso_indirectas',
                header: 'Peso Indirectas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'unidad_directas',
                header: 'Unidad Directas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'unidad_indirectas',
                header: 'Unidad Indirectas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'beneficio_directas',
                header: '%/s Beneficio Directas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'beneficio_indirectas',
                header: '%/s Beneficio Indirectas',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'venta_directas_metal',
                header: '%/s V.D. Metal',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'venta_directas_metal_ni',
                header: '%/s V.D. MetalNI',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'venta_indirectas_metal',
                header: '%/s V.I. Metal',
                type: 'numeric',
                format: '0,0.00',
              },
              {
                name: 'venta_indirectas_metal_ni',
                header: '%/s V.I. MetalNI',
                type: 'numeric',
                format: '0,0.00',
              },
            ]" />
          </div>
          <div class="tab">
            <field name="bancos" widget="handsontable" style="top: 5px; left: 5px" :columns="[
              { name: 'predet', header: 'Predet.', widget: 'checkbox' },
              {
                name: 'cuenta',
                header: 'Cuenta',
                renderer: rendererCuenta,
                allowInvalid: true,
              },
              { name: 'titular', header: 'Titular' },
              {
                name: 'tipo_cuenta',
                header: 'Tipo',
                widget: 'select',
                options: ['EU', 'INT'],
              },
              { name: 'swift', header: 'SWIFT' },
            ]" :htSettings="htSettingsBancos" />
          </div>
          <div class="tab">
            <field name="app_secciones_disponibles" widget="m2m" :filter="['codigo', '!=', 'PCK']"
              label="Secciones disponibles" searchable width="260px" style="top: 10px; left: 10px" :height="80" />
            <field name="app_familia_defecto" widget="m2o" dbAdapter="familia" label="Familia por defecto"
              labelProp="nombre" primary="codigo" searchable width="260px" style="top: 115px; left: 10px" />
            <field name="app_familia_oro_defecto" widget="m2o" dbAdapter="familia" label="Familia por defecto (oro)"
              labelProp="nombre" primary="codigo" searchable width="260px" style="top: 155px; left: 10px"
              :filter="[['seccion_id', '=', '1']]" />


            <field name="limite_ultimas_unidades" type="number" dec="0" label="Límite de últimas uds."
              help="Número de unidades a partir del cual se consideran últimas unidades en los artículos" searchable
              width="115px" style="top: 195px; left: 10px" />
            <field name="app_numero_maximo_dispositivos" type="number" dec="0" label="Máx. dispositivos"
              help="Número máximo de dispositivos permitidos para usar la APP" searchable width="125px"
              style="top: 195px; left: 130px" />
            <fa-icon icon="trash" v-if="mode == 'view'" @click="restaurarDispositivos"
              style="top: 210px; left: 260px; position: absolute; cursor: pointer;"
              v-tooltip="'Restaurar los dispositivos registrados'" />
            <field name="app_tiempo_maximo_sesion" type="number" dec="0" label="Tiempo máximo de sesión (horas)"
              width="50px" inline-label style="top: 240px; right: 387px" />

            <field name="app_ocultar_orden_codigo" widget="toggle" label="Ocultar orden por código"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 5px; right: 50px" />
            <field name="app_enviar_mail" widget="toggle" label="Enviar copia email del pedido al cliente"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 30px; right: 50px" />
            <field name="app_seleccionar_tarifa" widget="toggle" label="Permitir seleccionar tarifa"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 55px; right: 50px" />
            <field name="app_mostrar_total_unidades" widget="toggle" label="Mostrar total de unidades"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 80px; right: 50px" />
            <field name="app_mostrar_total_articulos" widget="toggle" label="Mostrar total de artículos"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 105px; right: 50px" />
            <field name="app_mostrar_importe_total" widget="toggle" label="Mostrar importe total"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 130px; right: 50px" />
            <field name="app_aplicar_reglas_unidades_minimas" widget="toggle" label="Aplicar reglas de unidades mínimas"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 155px; right: 50px" />
            <field name="app_permitir_modificar_reglas_unidades_minimas" widget="toggle"
              label="Pêrmitir modificar las regla de unidades mínimas" :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50" inline-label style="top: 180px; right: 50px" />
            <field name="app_geolocalizacion_inactiva" widget="toggle" label="Desactivar la geolocalización"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 205px; right: 50px" />
            <field name="app_mostrar_stock" widget="toggle" label="Mostrar stock"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 230px; right: 50px" />
            <field name="app_ocultar_numero_carrito" widget="toggle" label="Ocultar número del carrito"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 255px; right: 50px" />
            <field name="app_no_editar_oro" widget="toggle" label="No editar precio/margen en venta de oro"
              :labels="{ checked: 'Sí', unchecked: 'No' }" :width="50" inline-label style="top: 280px; right: 50px" />
          </div>
          <div class="tab">
            <field name="movimientos" widget="handsontable" style="top: 10px; left: 5px" :height="174" :minRows="7"
              :readonly="true" :columns="[
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: [
                    {
                      venta: 'Venta',
                      cobro_efectivo: 'Cobro efectivo',
                      cobro_banco: 'Cobro banco',
                      impago: 'Impago',
                      abono: 'Abono',
                    },
                  ],
                },
                { name: 'estado', header: 'Estado' },
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                { name: 'concepto', header: 'Concepto' },
              ]" />
            <span style="position: absolute; bottom: 5px; left: 5px">
              <div class="n_mov" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  padding: 0px 5px;
                  min-width: 3em;
                  text-align: center;
                  user-select: none;
                "></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 390px">
              <div class="eur_restante" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 522px">
              <div class="gr_restante" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "></div>
            </span>
          </div>
          <!-- <div class="tab-6">
                     <field name="publicar_cliente_web" widget="rcswitcher" ontext="Si" offtext="No" label="Publicar Cliente en la Web" width="70" inline-label style="top:42px; left:24px;"/>
                     <field name="usuario" widget="input" label="Usuario" inline-label placeholder="Usuario" width="220px" style="top:80px; left:34px;"/>
                     <field name="password" widget="input" label="Password" inline-label placeholder="Password" width="100px" style="top:110px; left:23px;"/>
                     <field name="tarifa_web" widget="input" label="T Web" inline-label width="25px" style="top:140px; left:40px;"/>
                     <field name="trabaja_cuenta_metal" widget="checkbox" label="Trabaja con cuenta de metal" inline-label style="top:96px; left:350px;"/>
                     <field name="write_date" widget="date" label="Fecha últ. modificación" inline-label style="top:126px; left:350px;"/>
                     </div>
                     <div class="tab-7">
                     <field name="agent_id" label="Agente" inline-label width="100px" widget="m2o_select" style="top:96px; left:350px;"/>
          </div>-->
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>Listado</div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<style>
.dialog-representantes .field[name="calculo_comisones"]>label {
  width: 100%;
}
</style>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CliProvRepMixin from "./../components/CliProvRepMixin.js";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, CliProvRepMixin],
  data: function () {
    var self = this;
    return {
      title: "Representantes",
      dbAdapter: "representante",
      primary: "codigo",
      defaultDataSearch: {
        activo: 1
      },
      defaultData: {
        activo: 1,
        calculo_comisones: "sin_restricciones",
      },
      sequence: {
        name: "representante",
        params: ["delegacion_id"],
      },
    };
  },
  methods: {
    restaurarDispositivos() {
      let self = this;
      if (!self.itemId) return;
      self.app.confirm("¿Seguro que desea restaurar todos los dispositivos registrados para este representante?", () => {
        window.DB.put("representante", { itemId: self.itemId, data: { dispositivos: false } }).then(_ => {
          self.app.toast("Se han restaurado todos los dispositivos de este representante", "success");
        })
      })
    }
  }
};
</script>